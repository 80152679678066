import { useTranslation } from "../../localization/useTranslation";
import { useEditTaskDialogDataMapping } from "./EditTaskDialog.hooks";
import { useEditTaskDialogStyles } from "./EditTaskDialog.styles";
import SubTasks from "../subTasks/SubTasks";
import {
    DotLegalFullScreenSpinner,
    DotLegalHeader,
    DotLegalPrettyScrollbar,
    DotLegalTextField,
    DotLegalTooltip,
    useIsOnSmallScreen,
} from "@dotlegal/dotlegal-ui-components";
import TaskDocuments from "./taskDocuments/TaskDocuments";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { Box, Button, darken, Dialog, DialogContent, Grid, IconButton, styled } from "@mui/material";
import MasterDataSection from "./masterDataSection/MasterDataSection";
import SubtasksSvg from "./SubTask.svg?react";
import Documentation from "./Documentation.svg?react";
import LinkTaskSvg from "./LinkTask.svg?react";
import TaskComments from "./taskComments/TaskComments";
import { AddRelatedTaskScenario, AddTaskClickEnum } from "./EditTaskDialog.types";
import TaskRelations from "./taskRelations/TaskRelations";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { AnnualWheelActivityFramework, AnnualWheelActivityType } from "../../annualWheel/annualWheelDialog/AnnualWheelDialog.types";
import DeleteTaskDialog from "../deleteTaskDialog/DeleteTaskDialog";
import { exhaustiveGuard } from "../../common/utilities.ts";
import TaskLocked from "./task-locked.svg?react";
import TrialUsageWarning from "../../common/components/TrialUsageWarning.tsx";
import { useState } from "react";
import TaskDialogHeader from "./taskDialogHeader/TaskDialogHeader.tsx";

export interface IEditTaskDialog {
    taskId: string;
    setSelectedTask: (value: string | null) => void;
    refetchTasks: () => void;
    setShowDeleteDialog: () => void;
    setYearByTaskYear: (year: number) => void;
}

export const buttonColor = "#F5F5F5";
export const TopButton = styled(Button)(({ theme }) => ({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 14,
    lineHeight: 1.5,
    borderRadius: "5px",
    backgroundColor: buttonColor,
    borderColor: buttonColor,
    color: theme.palette.primary.dark,
    "&:hover": {
        backgroundColor: darken(buttonColor, 0.1),
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: darken(buttonColor, 0.1),
    },
    "& img": {
        marginRight: 8,
    },
    "&.Mui-disabled": {
        color: theme.palette.primary.dark,
    },
}));

function EditTaskDialog(props: IEditTaskDialog) {
    const isOnSmallScreen = useIsOnSmallScreen();
    const { translateString } = useTranslation();
    const {
        task,
        updateTaskState,
        onTaskDocumentChange,
        addTask,
        setAddTask,
        addDocument,
        setAddDocument,
        addRelatedTask,
        setAddRelatedTask,
        showDeleteDialog,
        setShowDeleteDialog,
        taskUpdater,
        refetchTask,
    } = useEditTaskDialogDataMapping(props);

    const styles = useEditTaskDialogStyles();
    const { permissions } = useUserContext();
    const { platformFeatures } = usePlanContext();

    const showTaskDocuments = !!task;
    const showTaskComments = !!task;
    const showSubTasks = !!task;
    const showTaskRelations = task && task.type !== AnnualWheelActivityType.ProcessingActivityValidation;

    const [taskCommentsIsDoneLoading, setTaskCommentsIsDoneLoading] = useState<boolean>();
    const [subTasksIsDoneLoading, setSubTasksIsDoneLoading] = useState<boolean>();
    const [taskRelationsIsDoneLoading, setTaskRelationsIsDoneLoading] = useState<boolean>();
    const [taskDocumentsIsDoneLoading, setTaskDocumentsIsDoneLoading] = useState<boolean>();
    const [masterDataSectionIsDoneLoading, setMasterDataSectionIsDoneLoading] = useState<boolean>();

    const notPartOfCurrentPlan = task?.notPartOfCurrentPlan;
    const showDialogData: boolean =
        ((showTaskComments ? taskCommentsIsDoneLoading : true) &&
            (showSubTasks ? subTasksIsDoneLoading : true) &&
            (showTaskRelations ? taskRelationsIsDoneLoading : true) &&
            (showTaskDocuments ? taskDocumentsIsDoneLoading : true) &&
            masterDataSectionIsDoneLoading &&
            task !== undefined) ||
        (notPartOfCurrentPlan !== undefined && notPartOfCurrentPlan);

    const getValue = (value: string) => {
        return <Box sx={styles.text}>{value}</Box>;
    };

    function resetStates() {
        setSubTasksIsDoneLoading(undefined);
        setTaskCommentsIsDoneLoading(undefined);
        setTaskRelationsIsDoneLoading(undefined);
        setTaskDocumentsIsDoneLoading(undefined);
    }

    function onDialogClose() {
        props.setSelectedTask("");
        props.refetchTasks();
        resetStates();
    }

    function getRelatedTaskTopButton() {
        if (!showTaskRelations) {
            return;
        }

        const button = (
            <TopButton
                disabled={!platformFeatures.relatedActivitiesAndTasks || !permissions.taskPermissions.edit}
                onClick={() => setAddRelatedTask(AddRelatedTaskScenario.ShowContentAndOpenSuggenstions)}
            >
                <Box sx={{ marginRight: 1, "& svg": { height: 14 } }}>
                    <LinkTaskSvg />
                </Box>
                {translateString("addRelatedTasks")}
            </TopButton>
        );

        if (platformFeatures.relatedActivitiesAndTasks) {
            return button;
        }
        return (
            <DotLegalTooltip text={translateString("upgradePrivacyPlanToday")}>
                <span>{button}</span>
            </DotLegalTooltip>
        );
    }

    let borderPadding = 4;
    const content = (
        <Grid container>
            <Grid item xs={12} sm={12} md={7}>
                <DotLegalPrettyScrollbar>
                    <Box
                        sx={{
                            paddingRight: 2,
                            paddingTop: showDialogData ? 1 : 0,
                        }}
                    >
                        {showDialogData && (
                            <Box sx={{ "& > *:not(:last-child) ": { marginRight: 1 }, marginBottom: 2 }}>
                                <TopButton
                                    disabled={!permissions.taskPermissions.edit}
                                    onClick={() => {
                                        setAddTask(true);
                                    }}
                                >
                                    <Box sx={{ marginRight: 1, "& svg": { height: 14 } }}>
                                        <SubtasksSvg />
                                    </Box>
                                    {translateString("addSubTask")}
                                </TopButton>
                                {getRelatedTaskTopButton()}
                                <TopButton
                                    disabled={!permissions.taskPermissions.edit}
                                    onClick={() => {
                                        switch (addDocument) {
                                            case AddTaskClickEnum.None:
                                                setAddDocument(AddTaskClickEnum.ShowSection);
                                                break;
                                            case AddTaskClickEnum.ShowSection:
                                                setAddDocument(AddTaskClickEnum.ShowFileExplorer);
                                                break;
                                        }
                                    }}
                                >
                                    <Box sx={{ marginRight: 1, "& svg": { height: 14 } }}>
                                        <Documentation />
                                    </Box>
                                    {translateString("addDocumentation")}
                                </TopButton>
                            </Box>
                        )}
                        {showDialogData && (task?.description || task?.isAdHocTask) && (
                            <Box sx={{ marginBottom: 3 }}>
                                <DotLegalHeader marginBottom={0.5} marginTop={0} headerStyle="small">
                                    {translateString("description")}
                                </DotLegalHeader>
                                {task.isAdHocTask ? (
                                    <DotLegalTextField
                                        disabled={!permissions.taskPermissions.edit}
                                        placeholder={translateString("addDescription")}
                                        label=""
                                        variant="standard"
                                        value={task.description}
                                        debounce
                                        onChange={async (description) => taskUpdater.onDescriptionChange(description)}
                                        multiline
                                    />
                                ) : (
                                    getValue(task!.description)
                                )}
                            </Box>
                        )}
                        {showSubTasks && (
                            <SubTasks
                                passIsDoneLoading={(newValue: boolean) => {
                                    if (!subTasksIsDoneLoading) {
                                        setSubTasksIsDoneLoading(newValue);
                                    }
                                }}
                                disabled={!permissions.taskPermissions.edit}
                                taskId={props.taskId}
                                updateTaskState={() => updateTaskState()}
                                shouldAddTask={addTask}
                                onSuccessAddTask={() => setAddTask(false)}
                                allDataIsDoneLoading={showDialogData}
                            />
                        )}

                        {showTaskRelations && (
                            <TaskRelations
                                passIsDoneLoading={(newValue: boolean) => {
                                    if (!taskRelationsIsDoneLoading) {
                                        setTaskRelationsIsDoneLoading(newValue);
                                    }
                                }}
                                shouldAddRelatedTask={addRelatedTask === AddRelatedTaskScenario.ShowContentAndOpenSuggenstions}
                                showRelatedTasks={addRelatedTask >= AddRelatedTaskScenario.ShowContent}
                                updateRelatedTaskScenario={() => {
                                    if (addRelatedTask === AddRelatedTaskScenario.ShowContentAndOpenSuggenstions) {
                                        setAddRelatedTask(AddRelatedTaskScenario.ShowContent);
                                    }
                                }}
                                taskId={props.taskId}
                                onHideRelatedTasks={() => setAddRelatedTask(AddRelatedTaskScenario.None)}
                                allDataIsDoneLoading={showDialogData}
                                readOnly={!platformFeatures.relatedActivitiesAndTasks || !permissions.taskPermissions.edit}
                                isAdHocTask={task.isAdHocTask}
                            />
                        )}

                        {showTaskDocuments && (
                            <TaskDocuments
                                passIsDoneLoading={(newValue: boolean) => {
                                    if (!taskDocumentsIsDoneLoading) {
                                        setTaskDocumentsIsDoneLoading(newValue);
                                    }
                                }}
                                disabled={!permissions.taskPermissions.edit}
                                taskId={props.taskId}
                                updateTaskState={() => updateTaskState()}
                                updateDocumentState={(hasDocuments) => onTaskDocumentChange(hasDocuments)}
                                shouldAddDocument={addDocument === AddTaskClickEnum.ShowFileExplorer}
                                show={addDocument >= AddTaskClickEnum.ShowSection}
                                isDocumentationRequired={task?.documentationRequired}
                                onSuccessAddDocument={() => {
                                    if (addDocument === AddTaskClickEnum.ShowFileExplorer) {
                                        setAddDocument(AddTaskClickEnum.ShowSection);
                                    }
                                }}
                                onClose={() => setAddDocument(AddTaskClickEnum.None)}
                                allDataIsDoneLoading={showDialogData}
                            />
                        )}
                        {showTaskComments && (
                            <TaskComments
                                passIsDoneLoading={(newValue: boolean) => {
                                    if (!taskCommentsIsDoneLoading) {
                                        setTaskCommentsIsDoneLoading(newValue);
                                    }
                                }}
                                taskId={props.taskId}
                                taskName={task.name}
                                customId={task.customId}
                                deadline={task.deadline}
                                allDataIsDoneLoading={showDialogData}
                            />
                        )}
                    </Box>
                </DotLegalPrettyScrollbar>
            </Grid>
            <Grid hidden={!showDialogData} item xs={12} sm={12} md={5} paddingLeft={2} paddingTop={1}>
                <MasterDataSection
                    passIsDoneLoading={(newValue: boolean) => {
                        if (!masterDataSectionIsDoneLoading) {
                            setMasterDataSectionIsDoneLoading(newValue);
                        }
                    }}
                    taskId={task?.id}
                    masterData={
                        task
                            ? {
                                  customId: task.customId,
                                  deadline: task.deadline,
                                  documentationRequired: task.documentationRequired,
                                  hasDocument: task.hasDocument,
                                  isOverdue: task.isOverdue,
                                  isAdHocTask: task.isAdHocTask,
                                  legalEntityName: task.legalEntityName,
                                  name: task.name,
                                  type: task.type,
                              }
                            : undefined
                    }
                    isLoading={!task}
                    refetchTask={() => refetchTask()}
                />
            </Grid>
        </Grid>
    );
    const notPartOfPlanContent = (
        <Box sx={{ height: 500, display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{ mt: 3 }}>
                <TrialUsageWarning
                    textKey={"taskNotPartOfPlanWarning"}
                    headerKey={"taskIsLocked"}
                    values={{
                        planName: getPlanName(task?.framework),
                    }}
                />
            </Box>
            <Box sx={{ mt: 8 }}>
                <TaskLocked />
            </Box>
        </Box>
    );

    return (
        <Dialog
            sx={
                notPartOfCurrentPlan
                    ? {}
                    : {
                          "& .MuiPaper-root": {
                              height: "90%",
                          },
                      }
            }
            open
            fullWidth
            maxWidth={notPartOfCurrentPlan ? "md" : "xl"}
            onClose={onDialogClose}
            fullScreen={isOnSmallScreen}
        >
            {showDialogData && !notPartOfCurrentPlan && (
                <TaskDialogHeader
                    activityName={task?.name ?? ""}
                    taskName={task?.processingActivityName}
                    customTaskId={task?.customId}
                    processingActivtiyId={task?.processingActivityId ?? null}
                    isAdHocTask={task?.isAdHocTask}
                    onNameChange={taskUpdater.onNameChange}
                    borderPadding={borderPadding}
                    onDialogClose={onDialogClose}
                    setShowDeleteDialog={() => setShowDeleteDialog(true)}
                />
            )}

            <DialogContent
                sx={{
                    paddingTop: 0,
                    paddingRight: borderPadding,
                    paddingBottom: borderPadding,
                    paddingLeft: borderPadding,
                }}
            >
                {!showDialogData && (
                    <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <DotLegalFullScreenSpinner />
                    </Box>
                )}

                {notPartOfCurrentPlan ? notPartOfPlanContent : content}
            </DialogContent>

            {showDeleteDialog && task && (
                <DeleteTaskDialog
                    taskId={props.taskId!}
                    taskName={task.name}
                    onCloseDialog={() => setShowDeleteDialog(false)}
                    onDeleteClick={() => {
                        setShowDeleteDialog(false);
                        onDialogClose();
                    }}
                />
            )}
        </Dialog>
    );
}

function getPlanName(framework: AnnualWheelActivityFramework | null | undefined) {
    if (!framework) {
        return "";
    }
    switch (framework!) {
        case AnnualWheelActivityFramework.GDPR:
            return "data protection";
        case AnnualWheelActivityFramework.NIS2: {
            return "information security";
        }
        case AnnualWheelActivityFramework.InfoSec: {
            return "information security";
        }
        case AnnualWheelActivityFramework.ISO27001: {
            return "information security";
        }
        case AnnualWheelActivityFramework.ISO27002: {
            return "information security";
        }
        default: {
            exhaustiveGuard(framework);
        }
    }
}

export default EditTaskDialog;
