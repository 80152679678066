import { DotLegalTableWithControls, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { DotLegalButton } from "@dotlegal/dotlegal-ui-components";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import { DotLegalOverflowMenu } from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { useTranslation } from "../../localization/useTranslation";
import PolicyDocumentDialog from "./policyDocumentDialog/PolicyDocumentDialog";
import { usePolicyDocumentDataMapping } from "./PolicyDocuments.hooks";
import { PolicyDocument } from "./PolkcyDocuments.types";
import NoDocuments from "../../processingActivity/processingActivityOverview/processingActivitySharingsTab/noSharings.svg?react";
import { DeletePolicyDocumentDialog } from "./deletePolicyDocumentDialog/DeletePolicyDocumentDialog";
import { useDotLegalFileUploadDataMapping } from "../../common/components/dotLegalFileUploadTable/DotLegalFileUploadTable.hooks";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { isNullOrWhitespace } from "../../common/stringOperations";

function PolicyDocuments() {
    const { translateString, translateDate } = useTranslation();
    const { downloadDocument } = useDotLegalFileUploadDataMapping();
    const { permissions } = useUserContext();

    const {
        data,
        isLoading,
        selectedDocument,
        setSelectedDocument,
        showDocumentDialog,
        setShowDocumentDialog,
        hasDocuments,
        refetch,
        setShowDeleteDialog,
        showDeleteDialog,
        policyId,
    } = usePolicyDocumentDataMapping();

    function getMenuItems(document: PolicyDocument) {
        let menuItems = [];

        if (permissions.policyPermissions.edit) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setSelectedDocument({
                        documentId: document.documentId,
                        name: document.name,
                        fileName: "",
                        link: document.link,
                        created: document.created,
                    });
                    setShowDocumentDialog(true);
                },
            });
        }

        if (isNullOrWhitespace(document.fileName)) {
            menuItems.push({
                menuItem: translateString("openLink"),
                onClick: () => window.open(document.link!, "_blank"),
            });
        } else {
            menuItems.push({
                menuItem: translateString("downloadDocument"),
                onClick: () => downloadDocument(document.documentId, document.fileName),
            });
        }

        if (permissions.policyPermissions.edit) {
            menuItems.push({
                menuItem: translateString("delete"),
                onClick: () => {
                    setSelectedDocument({
                        documentId: document.documentId,
                        name: document.name,
                        fileName: "",
                        link: "",
                        created: document.created,
                    });
                    setShowDeleteDialog(true);
                },
            });
        }

        return menuItems;
    }

    return (
        <React.Fragment>
            <Typography
                sx={(theme) => ({ color: theme.palette.primary.dark, fontWeight: theme.typography.fontWeightBold, fontSize: theme.typography.pxToRem(16) })}
            >
                {translateString("documents")}
            </Typography>
            <DotLegalPaper>
                {hasDocuments ? (
                    <DotLegalTableWithControls
                        headers={GetDocumentHeader(translateString)}
                        defaultOrderBy={"name"}
                        defaultOrder={"desc"}
                        isLoading={isLoading}
                        data={data}
                        renderRow={(row, i) => (
                            <TableRow key={row.documentId} onClick={() => {}}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.fileName ?? row.link}</TableCell>
                                <TableCell>{translateDate(row.created)}</TableCell>
                                <TableCell align={"right"}>{<DotLegalOverflowMenu items={getMenuItems(row)} />}</TableCell>
                            </TableRow>
                        )}
                        paginationLabelOf={translateString("labelOf")}
                        getUserSpecificPageLength={() => undefined}
                        hideRowsPerPage
                        emptyText={translateString("noSharingsMatchesQuery")}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                    ></DotLegalTableWithControls>
                ) : (
                    <DotLegalEmptyState icon={<NoDocuments />} text={translateString("noDocumentsAttachedToLegalEntity")} />
                )}
                {permissions.policyPermissions.edit && (
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <DotLegalButton
                            buttonType={"primary"}
                            onClick={() => {
                                setSelectedDocument(undefined);
                                setShowDocumentDialog(true);
                            }}
                        >
                            {translateString("createNew")}
                        </DotLegalButton>
                    </Box>
                )}

                {showDocumentDialog && (
                    <PolicyDocumentDialog onDialogClose={() => setShowDocumentDialog(false)} selectedDocument={selectedDocument} onSave={() => refetch()} />
                )}

                {showDeleteDialog && (
                    <DeletePolicyDocumentDialog
                        onCloseDialog={() => setShowDeleteDialog(false)}
                        onSaveCorrect={() => refetch()}
                        policyId={policyId}
                        selectedDocument={selectedDocument!}
                    />
                )}
            </DotLegalPaper>
        </React.Fragment>
    );
}

export default PolicyDocuments;

function GetDocumentHeader(translateString: any) {
    const headers = Array<ITableHeader<PolicyDocument>>();
    headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
    headers.push({ property: "fileName", text: translateString("document"), align: "left", showOnMobile: true });
    headers.push({ property: "created", text: translateString("created"), align: "left", showOnMobile: true });
    return headers;
}
