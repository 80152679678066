import React from "react";
import StepSegment from "../../../../processingActivity/stepSegment/StepSegment";
import { Box } from "@mui/material";
import { DotLegalCheckbox, DotLegalMultiSelect, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../localization/useTranslation";
import { useLegalEntityDescriptionStepStyle } from "./LegalEntityDescriptionStep.styles";
import { LegalEntityManagementModel } from "../../LegalEntityManagement.types";
import { useLegalEntityDescriptionStepHook } from "./LegalEntityDescriptionStep.hooks";
import { ValidationError } from "../../../../common/validationError";
import TagsMultiSelect from "../../../../common/components/tagsMultiSelect/TagsMultiSelect";
import { useUserContext } from "../../../../auth/userContextProvider/UserContextProvider";
import { EntityType } from "../../../../common/Common.types.ts";

export interface ILegalEntityDescriptionStep {
    legalEntity: LegalEntityManagementModel;
    onNameChange: (name: string) => void;
    onDescriptionChange: (description: string) => void;
    onAddressChange: (address: string) => void;
    onZipCodeChange: (zipCode: string) => void;
    onCityChange: (city: string) => void;
    onCompanyRegistrationNumberChange: (companyRegistrationNumber: string) => void;
    onWebsiteChange: (website: string) => void;
    onTypesChange: (types: Array<string>) => void;
    onCountryChange: (type: string) => void;
    onIsApprovedChange: (isApproved: boolean) => void;
    validationErrors: Array<ValidationError>;
}

function LegalEntityDescriptionStep(props: ILegalEntityDescriptionStep) {
    const { translateString } = useTranslation();
    const styles = useLegalEntityDescriptionStepStyle();
    const { countriesIsLoading, getSelectableCountries, getLegalEntityTypeOptions } = useLegalEntityDescriptionStepHook();
    const { permissions } = useUserContext();

    return (
        <React.Fragment>
            <StepSegment size="medium">
                <Box sx={styles.container}>
                    <Box sx={styles.subContainer}>
                        <DotLegalTextField
                            label={translateString("name")}
                            value={props.legalEntity.name ?? ""}
                            onChange={props.onNameChange}
                            debounce
                            errorText={props.validationErrors.find((v) => v.field === "legalEntityName")?.error}
                        />

                        <DotLegalTextField
                            label={translateString("description")}
                            value={props.legalEntity.description ?? ""}
                            onChange={props.onDescriptionChange}
                            debounce
                            multiline
                            rows={3}
                        />

                        <DotLegalTextField
                            label={translateString("address")}
                            value={props.legalEntity.address ?? ""}
                            onChange={props.onAddressChange}
                            debounce
                        />

                        <DotLegalTextField
                            label={translateString("zipCode")}
                            value={props.legalEntity.zipCode ?? ""}
                            onChange={props.onZipCodeChange}
                            debounce
                        />

                        <DotLegalTextField label={translateString("city")} value={props.legalEntity.city ?? ""} onChange={props.onCityChange} debounce />

                        {permissions.canAccessTags && <TagsMultiSelect entityId={props.legalEntity.id} entityType={EntityType.LegalEntity} />}
                    </Box>
                    <Box sx={styles.subContainer}>
                        <DotLegalSelect
                            label={translateString("country")}
                            selectedItem={props.legalEntity.country === "" ? null : props.legalEntity.country}
                            onChange={(country) => props.onCountryChange(country!)}
                            options={getSelectableCountries()}
                            placeholder={translateString("country")}
                            noOptionsLabel={translateString("noOptions")}
                            disableClearable
                            isLoading={countriesIsLoading}
                        />

                        <DotLegalTextField
                            label={translateString("companyRegistrationNumber")}
                            value={props.legalEntity.companyRegistrationNumber ?? ""}
                            onChange={props.onCompanyRegistrationNumberChange}
                            debounce
                        />

                        <DotLegalTextField
                            label={translateString("website")}
                            value={props.legalEntity.website ?? ""}
                            onChange={props.onWebsiteChange}
                            debounce
                        />
                        {!props.legalEntity.isCustomerOwned && (
                            <DotLegalMultiSelect
                                options={getLegalEntityTypeOptions()}
                                chosenOptions={(props.legalEntity.types ?? []).map((x) => x.toString())}
                                onChange={props.onTypesChange}
                                placeholder={translateString("type")}
                                label={translateString("type")}
                                noOptionsLabel={translateString("noOptions")}
                            />
                        )}

                        <Box
                            sx={{
                                maxHeight: 37,
                                marginTop: 2,
                                marginBottom: 1,
                                marginLeft: -1,
                                "& .MuiFormControlLabel-root": {
                                    marginRight: 1,
                                },
                                "& > *": {
                                    maxHeight: 37,
                                },
                            }}
                        >
                            <DotLegalCheckbox
                                onChange={props.onIsApprovedChange}
                                tooltip={translateString("legalEntityApprovedTooltip")}
                                label={translateString("legalEntityApproved")}
                                checked={props.legalEntity.isApproved}
                            />
                        </Box>
                    </Box>
                </Box>
            </StepSegment>
        </React.Fragment>
    );
}

export default LegalEntityDescriptionStep;
