import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";
import { TaskStateEnum } from "../../annualWheel/AnnualWheel.types";
import { DotLegalHeader, DotLegalCompletedPercentage, DotLegalPercentageColor } from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { completedTaskPaperIcon, overdueTaskPaperIcon, inProgressTaskPaperIcon } from "../../common/icons";
import { useTranslation } from "../../localization/useTranslation";
import { useTaskStatusPaper } from "./TaskStatusPaper.styles";

export interface ITaskStatusPaperProps {
    indicator: TaskStateEnum.Overdue | TaskStateEnum.InProgress | TaskStateEnum.Completed;
    tasksCount: number | undefined;
    percentage: number;
    isLoading: boolean;
    onClick?: () => void;
    isSelected?: boolean;
    children?: any;
    headerTooltip?: string;
    wave: string;
}

function TaskStatusPaper(props: ITaskStatusPaperProps) {
    const styles = useTaskStatusPaper(props.wave, props.isSelected);
    const { translateString } = useTranslation();

    const getPaperData = () => {
        switch (props.indicator) {
            case TaskStateEnum.Overdue:
                return { header: translateString("overdueTasks"), color: props.percentage === 100 ? "green" : "red", icon: overdueTaskPaperIcon };
            case TaskStateEnum.InProgress:
                return { header: translateString("ongoingTasks"), color: "blue", icon: inProgressTaskPaperIcon };
            case TaskStateEnum.Completed:
                return { header: translateString("completedTasks"), color: "green", icon: completedTaskPaperIcon };
        }
    };

    const paperData = getPaperData();

    return (
        <DotLegalPaper sx={styles.paper} onClick={() => props.onClick!()} clickableRows={props.onClick !== undefined}>
            <DotLegalHeader marginBottom={1} headerStyle="medium" fontWeight={700} toolTip={props.headerTooltip}>
                {paperData.header}
            </DotLegalHeader>

            <Box sx={styles.contentContainer}>
                <Box sx={styles.taskCountContainer}>
                    <Box sx={styles.iconContainer}>{paperData.icon}</Box>

                    {!props.isLoading ? (
                        <Typography sx={styles.taskCounter}>{props.tasksCount ?? 0}</Typography>
                    ) : (
                        <Skeleton variant="text">
                            <DotLegalHeader headerStyle={"medium"}>10</DotLegalHeader>
                        </Skeleton>
                    )}
                </Box>

                <Box sx={styles.percentageContainer}>
                    <DotLegalCompletedPercentage
                        isLoading={props.isLoading}
                        completed={props.percentage}
                        fixedColor={paperData.color as DotLegalPercentageColor}
                    />
                    {props.children && <Box sx={styles.percentageChildren}> {props.children}</Box>}
                </Box>
            </Box>
        </DotLegalPaper>
    );
}

export default TaskStatusPaper;
