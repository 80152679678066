export enum EntityType {
    ProcessingActivity = 1,
    System = 2,
    LegalEntity = 3,
    Policy = 4,
    IncidentLog = 5,
    AnnualWheelActivity = 6,
    Task = 7,
    Risk = 8,
}
