import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { TaskRawStateEnum } from "../../annualWheel/AnnualWheel.types";
import { get, put } from "../../common/api/apiShared";
import { TrackingEvent, useTrackAIEvent } from "../../processingActivity/hooks/useTracking";
import { AddRelatedTaskScenario, AddTaskClickEnum, TaskSaveModel, TaskViewModel } from "./EditTaskDialog.types";
import { IEditTaskDialog } from "./EditTaskDialog";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";
import { useStateUrlParams } from "../../common/hooks/useStateUrlParams";

export function useEditTaskDialogDataMapping(props: IEditTaskDialog) {
    const queryClient = useQueryClient();
    const trackEvent = useTrackAIEvent();
    const optimisticUpdate = useOptimisticUpdate();

    const url = `/task/${props.taskId}`;
    const queryKey = "task" + props.taskId;

    const [addTask, setAddTask] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [addDocument, setAddDocument] = useState<AddTaskClickEnum>(AddTaskClickEnum.None);
    const [addRelatedTask, setAddRelatedTask] = useState<AddRelatedTaskScenario>(AddRelatedTaskScenario.None);

    const { data, refetch } = useQuery(queryKey, () => get<TaskViewModel>(url));

    useEffect(() => {
        if (data) {
            if (data.deadline) {
                props.setYearByTaskYear(new Date(data.deadline).getFullYear());
            }

            if (data.documentationRequired) {
                setAddDocument(AddTaskClickEnum.ShowSection);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const taskUpdater = {
        onNameChange: async (name: string) => {
            let model = { ...data! };
            model.name = name;
            await onNameAndDescriptionChange(model);
        },
        onDescriptionChange: async (description: string) => {
            let model = { ...data! };
            model.description = description;
            await onNameAndDescriptionChange(model);
        },
    };

    async function updateTaskState() {
        const tempTaskModel = { ...data! };

        if (tempTaskModel.taskState === TaskRawStateEnum.Ready) {
            await onTaskChange(tempTaskModel, true);
        }
    }

    async function onTaskChange(taskViewModel: TaskViewModel, updateState: boolean) {
        if (updateState) {
            updateStateToInProgressIfReady(taskViewModel);
        }

        updateTask(taskViewModel);

        trackEvent(TrackingEvent.AnnualWheelActivityTaskUpdated, { taskId: taskViewModel.id });
    }

    async function onNameAndDescriptionChange(viewModel: TaskViewModel) {
        await optimisticUpdate.putOnQueueAndSetQueryData(viewModel, queryKey, updateNameAndDescription, mapToSaveModel(viewModel));

        trackEvent(TrackingEvent.AnnualWheelActivityTaskUpdated, { taskId: data?.id });
    }

    async function onTaskDocumentChange(hasDocuments: boolean) {
        const tempTaskModel = { ...data! };
        tempTaskModel.hasDocument = hasDocuments;
        await onTaskChange(tempTaskModel, false);
    }

    return {
        task: data,
        refetchTask: refetch,
        onTaskChange,
        updateTaskState,
        onTaskDocumentChange,
        addTask,
        setAddTask,
        addDocument,
        setAddDocument,
        addRelatedTask,
        setAddRelatedTask,
        showDeleteDialog,
        setShowDeleteDialog,
        taskUpdater,
    };

    function updateTask(viewModel: TaskViewModel) {
        queryClient.setQueryData(queryKey, viewModel);
    }

    function updateStateToInProgressIfReady(taskViewModel: TaskViewModel) {
        if (taskViewModel.taskState === TaskRawStateEnum.Ready) taskViewModel.taskState = TaskRawStateEnum.InProgress;
    }

    function mapToSaveModel(viewModel: TaskViewModel): TaskSaveModel {
        return {
            title: viewModel.name,
            description: viewModel.description,
        };
    }

    async function updateNameAndDescription(saveModel: TaskSaveModel) {
        return await put<TaskSaveModel>("/Task/" + props.taskId, saveModel);
    }
}
