import { useState } from "react";
import { useValidator } from "../../../common/hooks/validation";
import { useTranslation } from "../../../localization/useTranslation";
import { TaskSaveModel } from "./AddTaskDialog.types";
import { post } from "../../../common/api/apiShared";
import { useMutation } from "react-query";
import { useSelectableBusinessAreaQuery, useSelectableUsersQuery } from "../../../common/hooks/useSelectableItemQueries";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { useUrlProvider } from "../../../useUrlProvider";
import { IAddTaskDialog } from "./AddTaskDialog";
import { useNavigate } from "react-router-dom";

export default function useAddTaskDialogHooks(props: IAddTaskDialog) {
    const { translateString } = useTranslation();
    const { getTaskManagementTaskDialogUrl } = useUrlProvider();
    const navigate = useNavigate();

    const [saveModel, setSaveModel] = useState<TaskSaveModel>({
        name: "",
        deadline: convertDate(new Date()),
        businessAreas: [],
        responsible: "",
        relation: props.relationInformation
            ? {
                  toEntityType: props.relationInformation?.toEntityType,
                  fromEntityId: props.relationInformation?.fromEntityId,
                  fromEntityType: props.relationInformation?.fromEntityType,
              }
            : undefined,
    });

    let responsibleQuery = useSelectableUsersQuery();
    let businessAreaQuery = useSelectableBusinessAreaQuery();

    const validation = useValidator<TaskSaveModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
            responsible: validators.validateNotEmpty(
                (item) => item.responsible,
                "",
                (item) => item.businessAreas.length > 0,
                translateString("annualWheelDataTaskResponsibleError")
            ),
            businessAreas: validators.validateNotEmpty(
                (item) => item.businessAreas,
                "",
                (item) => !isNullOrWhitespace(item.responsible),
                translateString("annualWheelDataTaskResponsibleError")
            ),
            deadline: validators.validateNotEmpty((item) => item.deadline, translateString("deadline")),
        }),
        []
    );

    validation.check(saveModel);

    const onModelChange = (updateFunc: (model: TaskSaveModel) => void) => {
        let tempSaveModel = { ...saveModel! };

        updateFunc(tempSaveModel);
        setSaveModel(tempSaveModel);
    };

    const saveMutation = useMutation(addTaskAPI, {
        onSuccess: (response: any) => {
            if (response.success()) {
                props.onDialogClose();
                navigate(getTaskManagementTaskDialogUrl(response.value().id));
            }
        },
    });

    const onSave = async () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        await saveMutation.mutateAsync();
    };

    function addTaskAPI() {
        return post<string>("/task", saveModel);
    }

    function convertDate(date: Date) {
        return new Date(date!.getFullYear(), date!.getMonth(), 15);
    }

    return {
        saveModel,
        setSaveModel,
        validation,
        onModelChange,
        onSave,
        responsibleData: responsibleQuery.data,
        responsibleIsLoading: responsibleQuery.isLoading,
        businessAreaData: businessAreaQuery.data,
        businessAreaIsLoading: businessAreaQuery.isLoading,
        convertDate,
        isSaving: saveMutation.isLoading,
    };
}
