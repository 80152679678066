import { DotLegalButton } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import useAddTaskButtonHooks from "./AddTaskButton.hooks";
import AddTaskDialog, { AddTaskRelationData } from "../addTaskDialog/AddTaskDialog";

export interface IAddTaskButton {
    relationInformation?: AddTaskRelationData;
}

function AddTaskButton(props: IAddTaskButton) {
    const { translateString } = useTranslation();
    const { showAddDialog, setShowAddDialog } = useAddTaskButtonHooks();

    return (
        <>
            <DotLegalButton buttonType={"primary"} onClick={() => setShowAddDialog(true)}>
                {translateString("createTask")}
            </DotLegalButton>

            {showAddDialog && <AddTaskDialog relationInformation={props.relationInformation} onDialogClose={() => setShowAddDialog(false)} />}
        </>
    );
}

export default AddTaskButton;
