import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { get } from "../../common/api/apiShared";
import { useDotLegalFileUploadDataMapping } from "../../common/components/dotLegalFileUploadTable/DotLegalFileUploadTable.hooks";
import { DotLegalSelectOption } from "../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { useStateUrlParamsArray } from "../../common/hooks/useStateUrlParams";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { useTranslation } from "../../localization/useTranslation";
import { ILegalEntityDocumentTabProps } from "./LegalEntityDocumentTab";
import { LegalEntityDocumentRow, SelectedLegalEntityDocument } from "./LegalEntityDocumentTab.types";
import { useParams } from "react-router-dom";

export function useLegalEntityDocumentTabDataMapping(props: ILegalEntityDocumentTabProps) {
    const queryClient = useQueryClient();
    const { translateString } = useTranslation();
    const { downloadDocumentVersion } = useDotLegalFileUploadDataMapping();
    const [searchedDocumentType, setSearchedDocumentType] = useStateUrlParamsArray("documentType", []);
    const [showAddEditDialog, setShowAddEditDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState<SelectedLegalEntityDocument>();
    const [showDeleteDocumentDialog, setShowDeleteDocumentDialog] = useState(false);
    const { permissions } = useUserContext();
    const { id } = useParams<{ id: string }>();

    const url = `/LegalEntity/${props.legalEntityId}/documents`;
    let { isLoading, data } = useQuery(url, () => get<Array<LegalEntityDocumentRow>>(url));

    let documentTypeOptions: Array<DotLegalSelectOption> = [];
    let documents;

    if (data) {
        documents = getFilteredRows(data).map((doc) => {
            return {
                documentId: doc.documentId,
                name: doc.name,
                documentType: doc.documentType,
                fileName: doc.fileName,
                owner: doc.owner,
                link: doc.link,
                version: doc.version,
                dataId: doc.dataId,
                status: doc.status,
                allGroupEntities: doc.allGroupEntities,
                groupEntities: doc.groupEntities,
                created: doc.created,
            };
        });

        CreateDocumentTypeFilter(data);
    }

    const handleRowClick = (documentId: string, dataId: string, fileName: string, link: string) => {
        if (!isNullOrWhitespace(fileName)) {
            downloadDocumentVersion(documentId, dataId, fileName);
        } else {
            window.open(link, "_blank");
        }
    };

    return {
        hasDocuments: isLoading || data!.length > 0,
        isLoading,
        documents,
        documentTypeOptions,
        searchedDocumentType,
        setSearchedDocumentType,
        getMenuItems,
        showAddEditDialog,
        setShowAddEditDialog,
        selectedDocument,
        setSelectedDocument,
        refetchDocuments,
        showDeleteDocumentDialog,
        setShowDeleteDocumentDialog,
        handleRowClick,
        showEditDialog,
        setShowEditDialog,
    };

    function refetchDocuments() {
        queryClient.refetchQueries(url);
    }

    function getMenuItems(document: LegalEntityDocumentRow) {
        let menuItems = [];

        if (permissions.companyDocumentationPermissions.edit) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setSelectedDocument({
                        documentId: document.documentId,
                        name: document.name,
                        legalEntityId: id,
                        isGroupEntity: props.isGroupEntity,
                    });
                    setShowEditDialog(true);
                },
            });
        }

        if (isNullOrWhitespace(document.fileName)) {
            menuItems.push({
                menuItem: translateString("openLink"),
                onClick: () => window.open(document.link, "_blank"),
            });
        } else {
            menuItems.push({
                menuItem: translateString("downloadDocument"),
                onClick: () => downloadDocumentVersion(document.documentId, document.dataId, document.fileName),
            });
        }

        if (permissions.companyDocumentationPermissions.delete) {
            menuItems.push({
                menuItem: translateString("delete"),
                onClick: () => {
                    setSelectedDocument({
                        documentId: document.documentId,
                        name: document.name,
                        legalEntityId: id,
                    });
                    setShowDeleteDocumentDialog(true);
                },
            });
        }

        return menuItems;
    }

    function CreateDocumentTypeFilter(documents: Array<LegalEntityDocumentRow>) {
        documentTypeOptions = documents
            .map((x) => x.documentType)
            .filter((e, i, self) => i === self.findIndex((s) => s.id === e.id))
            .map((x) => {
                return { id: x.id, name: x.name };
            });
    }

    function getFilteredRows(rows: Array<LegalEntityDocumentRow>) {
        let tempData = [...rows!];
        if (searchedDocumentType.length > 0 && !isNullOrWhitespace(searchedDocumentType[0])) {
            tempData = tempData.filter((doc) => searchedDocumentType.indexOf(doc.documentType.id) >= 0);
        }

        return tempData;
    }
}
