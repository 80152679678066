import { ApprovalOption, DialogStep } from "../AnnualWheel.types.ts";
import { useTranslation } from "../../localization/useTranslation.ts";
import { createElement, useEffect, useState } from "react";
import { AnnualWheelDurationFormModel } from "../annualWheelDialog/annualWheelDurationForm/AnnualWheelDurationForm.types.ts";
import { AnnualWheelResponsibleFormModel } from "../annualWheelDialog/annualWheelResponsibleForm/AnnualWheelResponsibleForm.types.ts";
import { AnnualWheelBaseInformationFormModel } from "../annualWheelDialog/annualWheelBaseInformationForm/AnnualWheelBaseInformationForm.types.ts";
import { AnnualWheelNameDescriptionSubtasksFormModel } from "../annualWheelDialog/annualWheelNameDescriptionSubtasks/AnnualWheelNameDescriptionSubtasks.types.ts";
import { IAnnualWheelAddEditDialogProps } from "./AnnualWheelAddEditDialog.tsx";
import { AnnualWheelActivityType, AnnualWheelPriority } from "../annualWheelDialog/AnnualWheelDialog.types.ts";
import { isNullOrWhitespace } from "../../common/stringOperations.ts";
import { useMutation, useQuery } from "react-query";
import { Trans } from "react-i18next";
import { get, post, put } from "../../common/api/apiShared.ts";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { AnnualWheelActivitySaveModel } from "./AnnualWheelAddEditDialog.types.ts";

export function useAnnualWheelAddEditDialog(props: IAnnualWheelAddEditDialogProps) {
    const { translateString } = useTranslation();
    const snackbar = useDotLegalSnackbar();
    const skipNameDescriptionSubtasksStep = props.selectedAnnualWheelActivityTemplate?.planNow;
    const [dialogStep, setDialogStep] = useState<DialogStep>(
        skipNameDescriptionSubtasksStep ? DialogStep.ActivityDurationData : DialogStep.NameDescriptionSubtasks
    );
    const [showFormError, setShowFormError] = useState<boolean>(false);
    const [selectedGroupCompaniesForValidateActivity, setSelectedGroupCompaniesForValidateActivity] = useState<Array<string>>([]);

    const url = "/annualWheelActivity/";
    const getAnnualWheelActivityUrl = `${url + props.annualWheelActivityId}`;
    const { isLoading, data } = useQuery(getAnnualWheelActivityUrl, () => get<AnnualWheelActivitySaveModel>(getAnnualWheelActivityUrl), {
        enabled: !isNullOrWhitespace(props.annualWheelActivityId),
    });

    const [annualWheelNameDescriptionSubTasksFormModel, setAnnualWheelNameDescriptionSubTasksFormModel] = useState(
        new AnnualWheelNameDescriptionSubtasksFormModel(
            props.selectedAnnualWheelActivityTemplate?.name ?? null,
            props.selectedAnnualWheelActivityTemplate?.description ?? null,
            props.selectedAnnualWheelActivityTemplate?.subTasks ?? []
        )
    );
    const [annualWheelDurationFormModel, setAnnualWheelDurationFormModel] = useState<AnnualWheelDurationFormModel>(
        new AnnualWheelDurationFormModel(
            props.startDate,
            props.selectedAnnualWheelActivityTemplate?.notificationPeriodInDays ?? 14,
            props.selectedAnnualWheelActivityTemplate?.interval ?? 12,
            props.selectedAnnualWheelActivityTemplate?.type,
            false
        )
    );
    const [annualWheelResponsibleFormModel, setAnnualWheelResponsibleFormModel] = useState<AnnualWheelResponsibleFormModel>(
        new AnnualWheelResponsibleFormModel([], "", [], ApprovalOption.ApprovalNotRequired, [])
    );
    const [annualWheelBaseInformationFormModel, setAnnualWheelBaseInformationFormModel] = useState<AnnualWheelBaseInformationFormModel>(
        new AnnualWheelBaseInformationFormModel(
            props.selectedAnnualWheelActivityTemplate?.priority ?? AnnualWheelPriority.Moderate,
            props.selectedAnnualWheelActivityTemplate?.customId,
            undefined,
            undefined,
            undefined,
            undefined,
            props.selectedAnnualWheelActivityTemplate?.framework
        )
    );

    useEffect(() => {
        if (data) {
            let nameDescriptionSubTaskForm = new AnnualWheelNameDescriptionSubtasksFormModel(
                data!.name,
                data!.description,
                data!.subTasks.map((x) => {
                    return { id: x, name: x };
                })
            );
            setAnnualWheelNameDescriptionSubTasksFormModel(nameDescriptionSubTaskForm);

            let responsbileForm = new AnnualWheelResponsibleFormModel(
                data!.businessAreas,
                data!.responsible,
                data!.groupCompanies,
                data!.approval,
                data!.assignees
            );
            setAnnualWheelResponsibleFormModel(responsbileForm);

            let durationForm = new AnnualWheelDurationFormModel(data!.startDate, data!.notificationPeriodInDays, data!.interval, data!.type, true);
            setAnnualWheelDurationFormModel(durationForm);

            let baseInformation = new AnnualWheelBaseInformationFormModel(
                data!.priority,
                data!.customId,
                data!.documentationRequired,
                data!.areas,
                data!.relatedActivities,
                undefined,
                undefined
            );
            setAnnualWheelBaseInformationFormModel(baseInformation);

            setSelectedGroupCompaniesForValidateActivity(data.groupCompanies.map((x) => x.groupCompanyId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const isEditActivity = data !== undefined;
    const activityId = props.annualWheelActivityId;
    const isValidatePaActivity =
        data?.type === AnnualWheelActivityType.ProcessingActivityValidation ||
        props.selectedAnnualWheelActivityTemplate?.type === AnnualWheelActivityType.ProcessingActivityValidation;

    const isFinalStep = () => {
        return dialogStep === DialogStep.ActivityBaseInformation;
    };
    const getOkBtnText = () => {
        if (!isFinalStep()) {
            return translateString("next");
        }

        if (isEditActivity) {
            return translateString("save");
        }

        return translateString("add");
    };

    function getDialogHeader() {
        if (isEditActivity) {
            return translateString("editActivity");
        } else {
            return translateString("createActivity");
        }
    }

    const getPreviousBtnText = () => {
        if (isEditActivity && dialogStep === DialogStep.NameDescriptionSubtasks) {
            return translateString("cancel");
        }

        return translateString("previous");
    };

    const onOkClick = () => {
        switch (dialogStep) {
            case DialogStep.NameDescriptionSubtasks: {
                /* Assigning a local variable, because the setShowFormError will not change the validation before we reach the if/else */
                const isInvalid = annualWheelNameDescriptionSubTasksFormModel.isInValid();
                setShowFormError(isInvalid);
                if (!isInvalid) {
                    setShowFormError(false);
                    setDialogStep(DialogStep.ActivityDurationData);
                }
                break;
            }
            case DialogStep.ActivityDurationData: {
                /* Assigning a local variable, because the setShowFormError will not change the validation before we reach the if/else */
                let isInvalid = annualWheelDurationFormModel!.isModelInvalid();
                setShowFormError(isInvalid);
                if (isValidatePaActivity && !isInvalid) {
                    setShowFormError(false);
                    setDialogStep(DialogStep.ActivityBaseInformation);
                } else if (!isInvalid) {
                    setShowFormError(false);
                    setDialogStep(DialogStep.ActivityResponsibleData);
                }
                break;
            }
            case DialogStep.ActivityResponsibleData: {
                /* Assigning a local variable, because the setShowFormError will not change the validation before we reach the if/else */
                let isInvalid = annualWheelResponsibleFormModel!.IsModelInvalid();
                setShowFormError(isInvalid);
                if (!isInvalid) {
                    setShowFormError(false);
                    setDialogStep(DialogStep.ActivityBaseInformation);
                }

                break;
            }
            case DialogStep.ActivityBaseInformation: {
                submitForm();
                break;
            }
        }
    };

    function submitForm() {
        if (isEditActivity) {
            updateActivity();
        } else {
            createActivity();
        }
    }

    const editMutation = useMutation(update);

    function update(id: string) {
        return put<{}>(`${url}${id}/`, mapToSaveModel());
    }
    const updateActivity = () => {
        editMutation.mutateAsync(activityId!, {
            onSuccess: () => {
                const activityName = getDialogHeader();
                snackbar.show(createElement(Trans, { i18nKey: "annualWheelActivityUpdated", values: { name: activityName } }));
                props.onSave();
                props.onDialogClose();
            },
        });
    };

    const createActivity = async () => {
        await createMutation.mutateAsync();
    };

    function save() {
        return post<{}>(url, mapToSaveModel());
    }

    const createMutation = useMutation(save, {
        onSuccess: () => {
            props.onSave();
            props.onDialogClose();
            snackbar.show(createElement(Trans, { i18nKey: "annualWheelActivityAdded", values: { name: annualWheelNameDescriptionSubTasksFormModel.name } }));
        },
    });

    function mapToSaveModel() {
        const saveModel: AnnualWheelActivitySaveModel = {
            businessAreas: annualWheelResponsibleFormModel?.businessAreas,
            documentationRequired: annualWheelBaseInformationFormModel?.documentationRequired,
            interval: annualWheelDurationFormModel.interval,
            notificationPeriodInDays: annualWheelDurationFormModel.notificationPeriodInDays,
            priority: annualWheelBaseInformationFormModel.priority,
            responsible: isNullOrWhitespace(annualWheelResponsibleFormModel?.responsible) ? undefined : annualWheelResponsibleFormModel.responsible,
            startDate: annualWheelDurationFormModel.startDate,
            type: props.selectedAnnualWheelActivityTemplate?.type,
            customTemplateId: props.selectedAnnualWheelActivityTemplate?.customTemplateId,
            areas: annualWheelBaseInformationFormModel.areas,
            customId: annualWheelBaseInformationFormModel.customId,
            groupCompanies: isValidatePaActivity
                ? selectedGroupCompaniesForValidateActivity.map((g) => {
                      return {
                          groupCompanyId: g,
                          userId: null,
                      };
                  })
                : annualWheelResponsibleFormModel?.groupCompanies,
            relatedActivities: annualWheelBaseInformationFormModel.relatedActivities,
            approval: annualWheelResponsibleFormModel?.approval,
            assignees: annualWheelResponsibleFormModel.assignees,
            tags: annualWheelBaseInformationFormModel.tags,
            name: annualWheelNameDescriptionSubTasksFormModel.name,
            description: annualWheelNameDescriptionSubTasksFormModel.description,
            subTasks: annualWheelNameDescriptionSubTasksFormModel.subTasks.map((x) => x.name),
        };

        return saveModel;
    }

    const onPreviousButtonClick = () => {
        switch (dialogStep) {
            case DialogStep.NameDescriptionSubtasks: {
                props.onPreviousClick();
                break;
            }
            case DialogStep.ActivityDurationData: {
                setDialogStep(DialogStep.NameDescriptionSubtasks);
                break;
            }
            case DialogStep.ActivityResponsibleData: {
                setDialogStep(DialogStep.ActivityDurationData);
                break;
            }
            case DialogStep.ActivityBaseInformation: {
                if (isValidatePaActivity) {
                    setDialogStep(DialogStep.ActivityDurationData);
                } else {
                    setDialogStep(DialogStep.ActivityResponsibleData);
                }

                break;
            }
        }
    };

    return {
        isLoading,
        getOkBtnText,
        getPreviousBtnText,
        getDialogHeader,
        isFinalStep,
        dialogStep,
        onOkClick,
        onPreviousButtonClick,
        annualWheelNameDescriptionSubTasksFormModel,
        setAnnualWheelNameDescriptionSubTasksFormModel,
        annualWheelBaseInformationFormModel,
        setAnnualWheelBaseInformationFormModel,
        annualWheelResponsibleFormModel,
        setAnnualWheelResponsibleFormModel,
        annualWheelDurationFormModel,
        setAnnualWheelDurationFormModel,
        showFormError,
        isEditActivity,
        isValidatePaActivity,
        activityId,
        selectedGroupCompaniesForValidateActivity,
        setSelectedGroupCompaniesForValidateActivity,
        inProgess: editMutation.isLoading || createMutation.isLoading,
    };
}
