import { DotLegalDatePicker, DotLegalDialog, DotLegalMultiSelect, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import useAddTaskDialogHooks from "./AddTaskDialog.hooks";
import { arrow } from "../../../common/icons.tsx";
import { EntityType } from "../../../common/Common.types.ts";

export interface AddTaskRelationData {
    fromEntityId: string;
    fromEntityType: EntityType;
    toEntityType: EntityType;
}

export interface IAddTaskDialog {
    onDialogClose: () => void;
    relationInformation?: AddTaskRelationData;
}

function AddTaskDialog(props: IAddTaskDialog) {
    const { translateString } = useTranslation();
    const {
        saveModel,
        validation,
        onModelChange,
        onSave,
        businessAreaData,
        businessAreaIsLoading,
        responsibleData,
        responsibleIsLoading,
        convertDate,
        isSaving,
    } = useAddTaskDialogHooks(props);

    return (
        <DotLegalDialog
            size="sm"
            buttonOkText={translateString("create")}
            header={translateString("createTask")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={() => onSave()}
            inProgress={isSaving}
            buttonOkIcon={arrow}
        >
            <DotLegalTextField
                label={translateString("name")}
                value={saveModel?.name ?? ""}
                debounce={false}
                errorText={validation?.getErrorsForDisplay("name")}
                onChange={(name) => onModelChange((model) => (model.name = name))}
            />
            <DotLegalSelect
                options={responsibleData}
                isLoading={responsibleIsLoading}
                selectedItem={saveModel.responsible}
                placeholder={translateString("noResponsible")}
                label={translateString("responsible")}
                onChange={(responsible) => onModelChange((model) => (model.responsible = responsible))}
                noOptionsLabel={translateString("noOptions")}
                errorText={validation?.getErrorsForDisplay("responsible")}
            />
            <DotLegalMultiSelect
                label={translateString("businessAreas")}
                options={businessAreaData}
                isLoading={businessAreaIsLoading}
                placeholder={translateString("noBusinessAreas")}
                onChange={(businessAreas) => onModelChange((model) => (model.businessAreas = businessAreas))}
                noOptionsLabel={translateString("noOptions")}
                chosenOptions={saveModel.businessAreas}
                errorText={validation?.getErrorsForDisplay("businessAreas")}
            />

            <DotLegalDatePicker
                label={translateString("deadline")}
                value={saveModel.deadline}
                onChange={(date: Date | null) => {
                    onModelChange((model) => (model.deadline = convertDate(date!)));
                }}
                view="month"
                language={"da"}
                error={validation.getErrorsForDisplay("deadline") !== undefined}
                helperMessage={validation?.getErrorsForDisplay("deadline")}
                minDate={new Date(new Date().getFullYear(), 0, 1)}
                maxDate={new Date(new Date().getFullYear() + 1, 11, 31)}
                disableKeyboardInput
            ></DotLegalDatePicker>
        </DotLegalDialog>
    );
}

export default AddTaskDialog;
