import React from "react";
import { useTagsMultiSelect } from "./TagsMultiSelect.hooks";
import DotLegalMultiSelectWithButton from "../dotLegalSelectWithButton/DotLegalMultiSelectWithButton";
import { useTranslation } from "../../../localization/useTranslation";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import TagDialog from "../../../masterData/tag/tagDialog/TagDialog";
import { SelectWrapper } from "../../../taskManagement/editTaskDialog/masterDataSection/MasterDataSection";
import { DotLegalChip, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import PlanInputFieldWrapper from "../../../plan/planInputFieldWrapper/PlanInputFieldWrapper";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { PurpleLockIcon } from "../../icons";
import { EntityType } from "../../Common.types.ts";

export interface TagsMultiSelectProps {
    entityId?: string;
    entityType: EntityType;
    customControl?: {
        onChange: (tagIds: Array<string>) => void;
        selectedTags: Array<string>;
    };
    variant?: "standard" | "outlined";
    disabled?: boolean;
    displayOnlyAsChips?: boolean;
    hideLabel?: boolean;
    noMargin?: boolean;
}

function TagsMultiSelect(props: TagsMultiSelectProps) {
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const { platformFeatures } = usePlanContext();

    const {
        isLoading,
        selectableTags,
        showCreateTagDialog,
        selectedTags,
        setShowCreateTagDialog,
        onTagCreated,
        onRemoveTagRelation,
        onAddTagRelation,
        onRelationChange,
        parentHandlesMutation,
    } = useTagsMultiSelect(props);

    const getLoadingBox = () => {
        return (
            <SelectWrapper>
                <DotLegalSelect
                    disabled={!permissions.taskPermissions.edit}
                    options={[]}
                    selectedItem={undefined}
                    disableClearable
                    onChange={() => {}}
                    placeholder={""}
                    variant={"standard"}
                    label={"Loading..."}
                    noOptionsLabel={"Loading..."}
                    isLoading={isLoading}
                    noMargin={props.noMargin}
                />
            </SelectWrapper>
        );
    };
    return (
        <>
            {props.displayOnlyAsChips ? (
                isLoading ? (
                    getLoadingBox()
                ) : (
                    <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: 1 }}>
                        {selectableTags
                            .filter((x) => selectedTags.indexOf(x.id) !== -1)
                            .map((tag) => {
                                return (
                                    <Box sx={{ marginRight: 1, marginBottom: 0.5 }} key={tag.id}>
                                        <DotLegalChip isSelected size="small" key={tag.id} color={tag.color} value={tag.name}></DotLegalChip>
                                    </Box>
                                );
                            })}
                    </Box>
                )
            ) : (
                <PlanInputFieldWrapper hasAccess={platformFeatures.tags} hoverText={translateString("upgradePrivacyPlanToday")}>
                    <DotLegalMultiSelectWithButton
                        disabled={props.disabled || !platformFeatures.tags}
                        isLoading={isLoading}
                        options={selectableTags}
                        chosenOptions={selectedTags}
                        onChange={(tags) => (parentHandlesMutation ? onRelationChange(tags) : {})}
                        label={props.hideLabel ? "" : translateString("tags")}
                        noOptionsLabel={translateString("noOptions")}
                        onButtonClick={() => setShowCreateTagDialog(true)}
                        buttonLabel={translateString("createTag")}
                        hasPermission={permissions.canManageMasterData}
                        onItemAdded={(item) => onAddTagRelation(item)}
                        onItemDeleted={(item) => onRemoveTagRelation(item)}
                        variant={props.variant ?? "outlined"}
                        icon={!platformFeatures.tags ? PurpleLockIcon : undefined}
                        noMargin={props.noMargin}
                    />
                </PlanInputFieldWrapper>
            )}
            {showCreateTagDialog && (
                <TagDialog onHideDialog={() => setShowCreateTagDialog(false)} onSave={(tagId) => onTagCreated(tagId)} selectedTag={undefined} />
            )}
        </>
    );
}

export default TagsMultiSelect;
