import { TaskRawStateEnum } from "../../annualWheel/AnnualWheel.types";
import { AnnualWheelActivityFramework, AnnualWheelActivityType, AnnualWheelPriority } from "../../annualWheel/annualWheelDialog/AnnualWheelDialog.types";

export interface TaskViewModel {
    id: string;
    name: string;
    processingActivityName: string;
    description: string;
    deadline: Date;
    taskState: TaskRawStateEnum;
    isOverdue: boolean;
    type: AnnualWheelActivityType;
    processingActivityId: string | null;
    documentationRequired: boolean;
    hasDocument: boolean;
    isAdHocTask: boolean;
    legalEntityName: string | null;
    customId: string | null;
    notPartOfCurrentPlan: boolean;
    framework: AnnualWheelActivityFramework | null;
}

export interface TaskSaveModel {
    title: string;
    description: string;
}

export enum AddTaskClickEnum {
    None = 0,
    ShowSection = 1,
    ShowFileExplorer = 2,
}

export enum AddRelatedTaskScenario {
    None = 0,
    ShowContent = 1,
    ShowContentAndOpenSuggenstions = 2,
}
